<script setup>
import { ref, computed } from 'vue';
import { BadgeCheckIcon, XCircleIcon, ExclamationCircleIcon, XIcon } from '@heroicons/vue/outline';

const props = defineProps({
  message: String,
  title: String,
  type: String,
});

const show = ref(true);

const wrapperClass = computed(() => {
  const type = props.type;
  const baseCss =
    'bg-white z-50 rounded-lg border-l-4 rounded-b text-teal-900 px-4 py-3 shadow-md fixed bottom-10 right-10';

  if (type === 'success') {
    return baseCss + ' border-green-500';
  }

  if (type === 'error') {
    return baseCss + ' border-red-500';
  }

  return baseCss + ' border-blue-500';
});

const closeMessage = () => {
  show.value = false;
};

const vClickOutside = {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.removeEventListener('click', el.clickOutsideEvent);
  },
};
</script>

<template>
  <Transition>
    <div v-show="show" v-click-outside="closeMessage" :class="wrapperClass" role="alert">
      <div class="flex justify-between">
        <div class="flex">
          <div class="py-1">
            <BadgeCheckIcon v-if="type == 'success'" class="text-green-500 h-6 w-6 mr-2" />
            <XCircleIcon v-if="type == 'error'" class="text-red-500 h-6 w-6 mr-2" />
            <ExclamationCircleIcon v-if="type == 'info'" class="text-blue-500 h-6 w-6 mr-2" />
          </div>
          <div>
            <p :class="{ 'font-semi-bold': true, 'mt-1': message ? false : true }">{{ title }}</p>
            <p v-if="message" class="text-sm">{{ message }}</p>
          </div>
        </div>
        <button class="text-gray-400 hover:text-gray-600 ml-4" @click="closeMessage">
          <XIcon class="h-5 w-5" />
        </button>
      </div>
    </div>
  </Transition>
</template>

<style lang="css">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
